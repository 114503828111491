import { Namespace, TFunction } from 'i18next';
import { FormType } from '../models/FormTypes';
import { NotificationResponse } from '../models/Notification';
import { CommentPlaceholder, FormPlaceholder, Placeholders, ImportJobPlaceholder, ExportJobPlaceholder } from '../models/Placeholders';
import DateUtils from './DateUtils';
import ObjectUtils from './ObjectUtils';

export interface NotificationPeriod {
  period: string;
  min: number;
  notifications?: NotificationResponse[];
}

/**
 * Categorises notifications into time periods
 * @param notifications Notifications to categorise
 */
export function categoriseByTime(notifications: NotificationResponse[], t: TFunction<Namespace>): NotificationPeriod[] {
  const periods: NotificationPeriod[] = [
    { period: t('common:notifications.periods.over-a-year-ago'), min: 366 },
    { period: t('common:notifications.periods.over-a-month-ago'), min: 31 },
    { period: t('common:notifications.periods.over-three-weeks-ago'), min: 29 },
    { period: t('common:notifications.periods.over-two-weeks-ago'), min: 15 },
    { period: t('common:notifications.periods.over-a-week-ago'), min: 8 },
    { period: t('common:notifications.periods.seven-days-ago'), min: 7 },
    { period: t('common:notifications.periods.six-days-ago'), min: 6 },
    { period: t('common:notifications.periods.five-days-ago'), min: 5 },
    { period: t('common:notifications.periods.four-days-ago'), min: 4 },
    { period: t('common:notifications.periods.three-days-ago'), min: 3 },
    { period: t('common:notifications.periods.two-days-ago'), min: 2 },
    { period: t('common:notifications.periods.one-day-ago'), min: 1 },
    { period: t('common:notifications.periods.today'), min: 0 },
  ];

  const period: NotificationPeriod[] = ObjectUtils.DeepClone(periods);
  const today = new Date(new Date().setHours(0, 0, 0, 0));

  for (let i = 0; i < notifications.length; i++) {
    const localCreatedUtc = new Date(notifications[i].createdUtc);
    const locationCreatedDate = new Date(localCreatedUtc.setHours(0, 0, 0, 0));
    for (let c = 0; c < period.length; c++) {
      const diff = DateUtils.getDaysBetween(today, locationCreatedDate);
      if (diff >= period[c].min) {
        if (period[c].notifications === undefined) {
          period[c].notifications = [];
        }
        period[c].notifications?.push(notifications[i]);
        break;
      }
    }
  }

  const categoriesWithNotifications = period.filter((x) => x.notifications?.length);

  return categoriesWithNotifications.reverse();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNotificationUrl = (placeholders: Record<string, any>) => {
  if (placeholders[Placeholders.COMMENT]) {
    const { formId, clientId, commentId } = placeholders[Placeholders.COMMENT] as CommentPlaceholder;
    return `/clients/${clientId}/forms/${formId}?edit#comment-${commentId}`;
  }

  if (placeholders[Placeholders.FORM]) {
    const { formId, clientId } = placeholders[Placeholders.FORM] as FormPlaceholder;
    return `/clients/${clientId}/forms/${formId}`;
  }

  if (placeholders[Placeholders.EXPORT_JOB]) {
    const { clientId } = placeholders[Placeholders.EXPORT_JOB] as ExportJobPlaceholder;
    return `/clients/${clientId}/organisation#data-exports`;
  }

  if (placeholders[Placeholders.IMPORT_JOB]) {
    const { id, type, clientId, templateId, moduleId } = placeholders[Placeholders.IMPORT_JOB] as ImportJobPlaceholder;
    const newSearch = new URLSearchParams(location.search);
    id && newSearch.set('importId', id);
    templateId && newSearch.set('templateId', templateId);
    clientId && type === FormType.Resource && newSearch.set('isLocal', 'true');

    const space = type === FormType.Resource ? `resources/${templateId}` : `modules/${moduleId}`;
    const query = newSearch.toString();
    return `/clients/${clientId}/${space}?${query}`;
  }

  return '';
};

const NotificationUtils = {
  categoriseByTime,
  getNotificationUrl,
};

export default NotificationUtils;
